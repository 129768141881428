<template>
  <div id="blog-tag-article-list">
    <div class="blog-tag-name">
      <i class="el-icon-myblog-discount"></i>{{ tagName }}
    </div>
    <blog-article-list-card
      v-for="(article, index) in articles"
      :key="index"
      :article="article"/>
    <el-pagination
      v-if="articlesTotal > 0"
      layout="prev, pager, next"
      :current-page.sync="currentPage"
      @current-change="handleCurrentChange"
      :page-size="pageSize"
      :total="articlesTotal">
    </el-pagination>
  </div>
</template>

<script>
import BlogArticleListCard from '@/components/BlogArticleListCard'

export default {
  name: 'blog-tag-list',
  components: {
    BlogArticleListCard
  },
  data () {
    return {
      articles: [],
      articlesTotal: 0,
      currentPage: 1,
      pageSize: 5,
      tagName: '标签'
    }
  },
  watch: {
    '$route.params' () {
      this.getTag()
      this.getArticlesList()
    }
  },
  methods: {
    getArticlesList () {
      this.articles = []
      if (this.$route.params.tagId) {
        this.$http({
          url: this.$api.BLOG_GET_ARTICLES_LIST_URL,
          method: 'GET',
          params: {
            tagId: this.$route.params.tagId,
            page: this.currentPage,
            pageSize: this.pageSize
          }
        }).then(response => {
          if (response.status === 'success') {
            this.articlesTotal = response.data.total
            for (const item of response.data.result) {
              this.articles.push(item)
            }
          } else {
            this.$message.error('服务器好像出错了...')
          }
        }).catch(() => {
          this.$message.error('哎呀...网络出错了，刷新一下吧')
        })
      } else {
        this.$router.push({ name: 'TagsIndex' })
      }
    },
    getTag () {
      this.$http({
        url: this.$api.BLOG_GET_TAG_COUNT_URL,
        method: 'GET',
        params: {
          tagId: this.$route.params.tagId
        }
      }).then(response => {
        if (response.status === 'success') {
          this.tagName = response.data.result[0].name
        } else {
          this.$message.error('服务器好像出错了...')
        }
      }).catch(() => {
        this.$message.error('哎呀...网络出错了，刷新一下吧')
      })
    },
    handleCurrentChange (val) {
      this.currentPage = val
      this.getArticlesList()
    }
  },
  created () {
    this.getTag()
    this.getArticlesList()
  }
}
</script>

<style lang="stylus" scoped>
.blog-tag-name
  text-align: left
  font-size: 16pt
  margin-bottom: 20px
  font-weight: 600
  i
    margin-right: 10px
</style>
